/* General
  * Use this file for general styles that are not specific to a component e.g. Spacing, Breakpoints.
*/

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  color: var(--white);
  font-family: $font-primary;
  background: #232D3D;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  width: 100%;
}

// circle dot
.insight-mode-indicator {

  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $primary-color;
  z-index: 9999;
  transition: opacity 0.3s ease-in-out;
  //cursor: pointer;
}

img {
  border-radius: 6px;
}
