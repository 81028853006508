/* Theme
    * Use this theme file for client specific styles and variables.
 */

// 1. Colors
$primary-color: #82e1f2;
$secondary-color: #ffffff;
$tertiary-color: #000000;
$bg-color: linear-gradient(#2f3847, #212b3a);

// 2. Fonts
$font-primary: cera-pro, sans-serif;

:root {
  --primary-color: #82e1f2;
  --white: #fff;

  // gradients
  --gradient-base: linear-gradient(#2f3847, #212b3a);

  // 2. Fonts
  --font-primary: cerapro, sans-serif;
}
